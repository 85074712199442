<template>
  <div>
    <div v-if="!normalizedData">
      No data to show!
    </div>
    <v-row v-else class="mx-0 primary darken-2">
      <v-col cols="6">
        <v-slider v-model="xGridLength"
                  hide-details
                  :min="graphWidthRange.min"
                  :max="graphWidthRange.max"
                  height="30"
                  prepend-icon="$vuetify.icons.widthZoom"
                  color="accent"
                  style="color: black !important;"/>
      </v-col>
      <v-col cols="6">
        <v-slider v-model="yGridHeight"
                  hide-details
                  :min="graphHeightRange.min"
                  :max="graphHeightRange.max"
                  height="30"
                  prepend-icon="$vuetify.icons.heightZoom"
                  color="accent"
                  style="color: black !important;"/>
      </v-col>
    </v-row>
    <div v-if="isValidData" :style="containerStyle" class="py-2 primary darken-1" ref="graphContainer">
      <svg :style="graphStyle">
        <g class="grid">
          <line :x1="yGridX" :x2="yGridX" :y1="yGridStart" :y2="yGridStart + yGridHeight"/>
        </g>
        <g class="grid">
          <line :x1="xGridStart" :x2="xGridStart+xGridLength" :y1="yGridStart + yGridHeight"
                :y2="yGridStart + yGridHeight"/>
        </g>
        <g class="labels x-labels">
          <text v-for="(timeStamp) in xAxisEndTime + 1" :key="timeStamp"
                :x="getXAxisLocation(timeStamp - 1)"
                :y="yGridStart + yGridHeight + 20">
            {{ timeStamp - 1 }}
          </text>
        </g>
        <g class="labels y-labels">
          <text v-for="({pitch, midiNote}) in frequencyTable" :key="pitch"
                :x="xGridStart - 35" :y="getYAxisLocation(midiNote)">
            {{ pitch }}
          </text>
        </g>
        <polyline fill="none"
                  :stroke="'#FFBF00'"
                  stroke-width="2"
                  :points="getExercisePolyLines"/>
        <polyline v-for="(points, i) in getPlayerPitchPolyLines" :key="i"
                  fill="none"
                  :stroke="'#00FF07'"
                  stroke-width="2.5"
                  :points="points"/>
      </svg>
    </div>
  </div>
</template>

<script>
import {
  frequencyToFloatMidi, getFrequencyTableFromPitches, getPolyLinesFromData, getStairsPolyLineFromData, mapToRange,
  medianFilterSingle,
  removeAdjacentDuplicates, toFlatNote
}                    from 'framework/utilities/Utilities'
import { Frequency } from 'tone'

// @todo make sure that it starts from the lowest pitch from both the exercise and user input and ends on the highest.
export default {
  name:     'AccuracyTimeline',
  props:    {
    data: Object
  },
  mounted() {
    const isMobile      = this.$vuetify.breakpoint.xs
    this.yGridHeight    = isMobile ? 300 : 400
    this.graphContainer = this.$refs.graphContainer
  },
  data() {
    return {
      frequencyTable:    null,
      normalizedData:    [],
      pianoExerciseData: [],
      isValidData:       false,
      yGridX:            65,
      yGridHeight:       400,
      yGridStart:        20,
      xGridY:            450,
      xGridLength:       800,
      xGridStart:        65,
      graphContainer:    null,
      xAxisEndTime:      0
    }
  },
  computed: {
    containerStyle:          function() {
      return {
        maxHeight: '500px',
        overflow:  'auto'
      }
    },
    graphStyle:              function() {
      return {
        width:  this.xGridStart + this.xGridLength + 50,
        height: this.yGridStart + this.yGridHeight + 50
      }
    },
    getPlayerPitchPolyLines: function() {
      const polyLines = getPolyLinesFromData(this.normalizedData)
      return polyLines.map(polyLine => this.reducePolyLineArrayToString(polyLine))
    },
    getExercisePolyLines:    function() {
      const polyLine = getStairsPolyLineFromData(this.pianoExerciseData)
      return this.reducePolyLineArrayToString(polyLine)
    },
    graphWidthRange:         function() {
      const base = this.$store.getters['environment/getWidth'] / this.xAxisEndTime
      return {
        min: base * 2,
        max: base * 13
      }
    },

    graphHeightRange: function() {
      return {
        min: 150,
        max: 1000
      }
    }
  },
  methods:  {
    getYAxisLocation(midiNote) {
      const startMidi = this.frequencyTable[0].midiNote
      const lastMidi  = this.frequencyTable[this.frequencyTable.length - 1].midiNote
      return this.yGridStart + this.yGridHeight - mapToRange(
          midiNote,
          startMidi,
          lastMidi,
          this.yGridStart,
          this.yGridHeight
      )
    },
    getXAxisLocation(value) {
      return mapToRange(value, 0, this.xAxisEndTime, this.xGridStart, this.xGridStart + this.xGridLength)
    },
    getFrequencyRange() {
      const frequencies = this.normalizedData.map(({ frequency }) => frequency)
                              .filter(el => !!el)
      if( !frequencies.length) {
        this.isValidData = false
        return
      }
      this.isValidData    = true
      const lowest        = Math.min(...frequencies)
      const highest       = Math.max(...frequencies)
      const lowestMidi    = frequencyToFloatMidi(lowest) - 2
      const highestMidi   = frequencyToFloatMidi(highest) + 2
      const lowestPitch   = toFlatNote(Frequency(lowestMidi, 'midi').toNote())
      const octaveRange   = Math.ceil(highestMidi - lowestMidi)
      this.frequencyTable = getFrequencyTableFromPitches(lowestPitch, octaveRange)
    },
    reducePolyLineArrayToString(polyLine) {
      return polyLine.map(({ x, y }) => {
        return {
          x: this.getXAxisLocation(x),
          y: this.getYAxisLocation(y)
        }
      }).reduce((acc, { x, y }) => `${ acc } ${ x }, ${ y }`, '')
    },
    updateXAxisTimeEnd() {
      const data             = this.normalizedData
      const lastIndex        = data.length - 1
      // Either the last element or the element before it must have a time value
      const lastRecordedTime = data[lastIndex].time || data[lastIndex - 1].time
      this.xAxisEndTime      = Math.ceil(lastRecordedTime) + 1
      this.xGridLength       = this.$store.getters['environment/getWidth'] / this.xAxisEndTime * 13
    }
  },
  watch:    {
    data: {
      immediate: true,
      handler({ exercise, detections = [] } = {}) {
        const isNoDetectionMade = detections.every(({ frequency }) => frequency === -1)
        if(isNoDetectionMade) {
          this.normalizedData    = null
          this.pianoExerciseData = null
          return
        }
        this.pianoExerciseData = exercise
        const dataFrequencies  = detections.map(({ frequency }) => frequency)
        const normData         = detections.map(({ frequency, time }, index) => {
          if(frequency === -1) {
            return {}
          }
          const normalizedFrequency = medianFilterSingle(dataFrequencies, index, 5, (val) => val && val !== -1)
          const midi                = frequencyToFloatMidi(normalizedFrequency)
          return {
            frequency: normalizedFrequency,
            midi,
            time
          }
        })
        this.normalizedData    = removeAdjacentDuplicates(normData, (e1, e2) => e1.midi === e2.midi)
        this.getFrequencyRange()
        this.updateXAxisTimeEnd()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.labels {
  font-size: 11px;
  fill: white;
  @media (max-width: 576px) {
    font-size: 8px;
  }

  .x-labels {
    text-anchor: middle;
  }

  .y-labels {
    text-anchor: end;
  }
}

.grid {
  stroke: #CCCCCC;
  stroke-dasharray: 0;
  stroke-width: 1;
}
</style>